import gql from 'graphql-tag';
export const GET_STUDENT_PORTFOLIO = gql`
  query GetMyStudPortfolios($input: GetManyStudentPortfoliostInput) {
    getMyStudPortfolios(input: $input) {
      currentPage
      pageSize
      totalCount
      totalPages
      data {
        id
        uuid
        name
        display
        socialLinks
        description
        logo
        bannerImage
        state
        email
        credentials
        userGroup
        feedback
        recommendation
        goal
        createdAt
        updatedAt
      }
    }
  }
`;
