import gql from 'graphql-tag';
export const GET_MY_CREDENTAIL_SCHEMA = gql`
  query GetMyCredentialSchemas {
    getMyCredentialSchemas {
      data {
        id
        name
        logo
        description
        jsonFormat
        jsonldFormat
        selfAttested
      }
      currentPage
      pageSize
      totalCount
      totalPages
    }
  }
`;
